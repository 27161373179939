@import "../../styles/all";

.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 1rem;

  &__error {
    text-align: center;
    font-size: 5rem;
    width: 100%;
  }

  &__text {
    color: $bright_orange;
    text-align: center;
    font-size: 2em;
    width: 100%;
  }
}
