@import "../../styles/all";

.latest-articles {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(0.25turn, white, #f9f5ed);

  &__container {
    width: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    max-width: 80rem;
  }

  &__left {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 1.5rem;
  }

  &__title {
    font-size: 2rem;
    font-weight: bold;
  }

  &__tile {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 2rem;
    padding-bottom: 0;
  }

  &__right {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    padding: 2rem;
    padding-top: 0;
  }
}

@include tablet {
  .latest-articles {
    &__container {
      padding: 1.5rem;
      display: flex;
      flex-direction: row;
    }

    &__left {
      width: 60%;
      display: flex;
      flex-direction: column;
      text-align: left;
      // gap: 1.5rem;
    }

    &__title {
      font-size: 2rem;
      font-weight: bold;
    }

    &__tile {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      padding: 2rem;
    }

    &__right {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 40%;
      padding: 2rem;
    }
  }
}
