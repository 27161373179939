@import "../../styles/all";

.categories {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 0;

  &__top-bar {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__search {
    width: 100%;
    line-height: 2rem;
    font-size: 1rem;
    padding: 0.3rem 2.4rem 0.3rem 0.5rem;
    border: #808080 solid 1px;
    outline: none;
    background-image: url("../../assets/images/search.png");
    background-repeat: no-repeat;
    background-size: 1rem 1rem;
    background-position: bottom 0.9rem right 0.5rem;

    &:active,
    &:focus {
      border: rgb(136, 212, 255) solid 1px;
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    padding: 1.5rem 1rem;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

@include tablet {
  .categories {
    max-width: 80rem;
    gap: 2rem;
    padding: 0 2rem 2rem 2rem;

    &__top-bar {
      display: flex;
      flex-direction: row;
      justify-content: right;
      padding-bottom: 1rem;
      border-bottom: solid rgb(215, 215, 215) 2px;
    }
    &__search {
      width: 20rem;
      border-radius: 0.25rem;
    }
  }
}
