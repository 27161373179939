@import "../../styles/all";

.video {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  width: 100%;

  &__container {
    position: relative;
    width: 100%;
    padding-bottom: 56.25%;
    max-width: 75rem;
  }

  &__container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
  }

  @include tablet {
    .video {
      padding: 2rem 4rem;
    }
  }
}
