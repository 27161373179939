@import "colors";

@mixin tablet {
  @media screen and (min-width: $width-tablet) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: $width-desktop) {
    @content;
  }
}

@mixin button {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.1rem 1rem;
  line-height: 2.5rem;
}
