@import "../../styles/all";

.RichEditor-root {
  background: #fff;
  font-family: "Georgia", serif;
  padding: 0.8rem 1rem;
  border-radius: 0.5rem;
  outline: none;
  border: solid gray 2px;
  font-size: 1rem;
  width: 100%;
  max-width: 50rem;
  margin: 1rem;
  margin-top: 0;
  padding-top: 9rem;

  &::placeholder {
    color: gray;
  }

  &:hover,
  &:focus {
    border-color: $bright_orange;
  }

  &--error {
    border-color: red;
  }
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: "Helvetica", sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}
blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: "Hoefler Text", "Georgia", serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.article-form {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__title-text {
    text-align: center;
    font-size: 1.5rem;
    color: rgb(88, 88, 88);
    margin-bottom: 0.5rem;
  }

  &__title {
    font-size: 1rem;
    color: rgb(88, 88, 88);
    margin-bottom: 1rem;
  }

  &__input {
    width: 100%;
    height: 2.5rem;
    padding: 0.25rem 1rem;
    border-radius: 0.5rem;
    outline: none;
    border: solid gray 2px;
    font-size: 1rem;
    margin-bottom: 1rem;

    &::placeholder {
      color: gray;
    }

    &:hover,
    &:focus {
      border-color: $bright_orange;
    }

    &--error {
      border-color: red;
    }
  }

  &__button {
    @include button;
  }

  &__category {
    width: 12.5rem;
    padding: 0.5rem 1rem;
    background-color: white;
    border-radius: 0.5rem;
    border: 2px solid $neutral_orange;
    color: $dark_orange;
    margin: 1rem;
    margin-top: 0;
  }
}

@include tablet {
  .article-form {
    padding: 2rem;
    max-width: 50rem;

    &__title {
      font-size: 1.2rem;
    }
  }
}

/* file upload button */
input[type="file"]::file-selector-button {
  border-radius: 0.25rem;
  padding: 0 1rem;
  height: 2.5rem;
  cursor: pointer;
  background-color: white;
  border: 2px solid $bright_orange;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 1rem;
  margin-bottom: 1rem;
  transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
  background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
  background-color: #e5e7eb;
}
