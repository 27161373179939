@import "./styles/all";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  // font-family: "Inter";
  font-family: "myriad-pro", sans-serif;
}
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
}
