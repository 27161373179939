@import "../../styles/all";

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 10rem 1.5rem 2rem 1.5rem;

  &__title {
    margin: 1rem 0;
    font-size: 2rem;
    font-weight: bold;
  }

  &__paragraph {
    text-align: justify;
    font-size: 1.3rem;
  }
}

@include tablet {
  .about {
    padding: 1rem 1.5rem 2rem 1.5rem;
    align-items: flex-start;
    max-width: 80rem;

    &__title {
      width: 36%;
    }

    &__paragraph {
      font-size: 1.4rem;
    }
  }
}
