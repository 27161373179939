@import "../../styles/all";
* {
  box-sizing: border-box;
}
.hero {
  padding-top: 9rem;
  background-color: #243b47;
  width: 100vw;
  display: flex;
  justify-content: center;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
    // position: relative;
    height: 16.5rem;
  }

  &__text {
    font-size: 1.4rem;
    font-style: italic;
    padding: 1rem 2rem;
    width: 100%;
    color: white;
    font-family: "myriad-pro", sans-serif;
  }

  &__image-container {
    width: 100%;
    z-index: 30;
    display: flex;
    justify-content: center;
  }

  &__image {
    width: 90%;
    height: 20rem;
    object-fit: cover;
  }
}

@include tablet {
  .hero {
    &__container {
      max-width: 80rem;
      flex-direction: row;
    }

    &__text {
      width: 45%;
      min-width: 14rem;
    }

    &__image-container {
      height: 23rem;
      display: flex;
      align-items: flex-start;
    }

    &__image {
      height: 23rem;
      object-fit: cover;
    }
  }
}
