@import "../../styles/all";

.loading {
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 60vh;

  // https://www.w3schools.com/howto/howto_css_loader.asp
  &__spin {
    height: 7.5rem;
    width: 7.5rem;

    border: 1rem solid lightgray;
    border-top: 1rem solid $bright_orange;
    border-radius: 50%;
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
