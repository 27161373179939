@import "../../styles/all";

.article {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: justify;
  width: 100%;
  padding: 10rem 2rem 1.5rem 2rem;

  &__image {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    max-width: 62.5rem;
    margin-bottom: 1.75rem;
  }

  &__title {
    font-size: 2.8rem;
    color: $dark_purple;
    margin-bottom: 1rem;
    text-align: left;
  }

  &__metadata {
    font-size: 1rem;
    color: gray;
    margin-bottom: 2rem;
    text-align: left;
  }

  &__body {
    font-size: 1.3rem;
  }
}

@include tablet {
  .article {
    padding: 2rem;
    padding-top: 9.5rem;
    max-width: 62.5rem;

    &__title {
      font-size: 4rem;
      color: $dark_purple;
    }

    &__body {
      font-size: 1.5rem;
    }
  }
}

li {
  margin-left: 2.5rem;
  text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: left;
}
