@import "../../styles/all";

.footer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #243b47;
  padding: 1rem;

  &__container {
    width: 100%;
    max-width: 80rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
  }

  &__logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  &__logo {
    background-image: url("../../assets/images/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 4rem;
    height: 4rem;
  }

  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }

  li {
    padding: 0;
    margin: 0;
  }

  &__list-link {
    text-decoration: none;
    color: white;
  }

  &__text {
    padding-left: 1.5rem;
    color: white;
    font-size: 2rem;
    padding-top: 0.5rem;
  }
}

@include tablet {
  .footer {
    padding: 2rem;
    &__container {
      flex-direction: row;
      padding: 2rem;
    }

    &__logo-container {
      width: 50%;
      justify-content: left;
    }

    &__list {
      width: 50%;
      align-items: flex-start;
    }
  }
}
