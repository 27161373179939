@import "../../styles/all";
* {
  text-decoration: none;
}
.tile {
  width: 43%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  &--full-width {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }

  &__image {
    width: 100%;
    aspect-ratio: 0.82;
    object-fit: cover;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &--full-width {
      width: 100%;
      aspect-ratio: 16/9;
      object-fit: cover;
      border-radius: 0.25rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__title {
    display: flex;
    margin: 0;
    color: black;
    padding: 1rem;
    padding-bottom: 0.5rem;
    font-size: 1.2rem;
    font-weight: normal;
    justify-content: flex-start;
    padding-left: 0;

    &--full-line {
      justify-content: flex-start;
    }
  }

  &__date {
    color: rgb(80, 80, 80);
    font-size: 1rem;
    padding-left: 0.5rem;
    display: inline-block;
    font-weight: 400;
  }

  &__category {
    display: inline-block;
    font-size: 0.8rem;
    width: fit-content;
    padding: 0.2rem 0.3rem;
    background-color: #139178;

    color: white;
    margin-bottom: 1.5rem;
  }
}

@include tablet {
  .tile {
    width: 30%;
    max-width: 21.875rem;

    &__title {
      font-size: 1.6rem;
    }
  }
}
