@import "../../styles/all";

.login-signup-image {
  display: none;

  @include tablet {
    width: 100%;
    max-width: 30rem;
    height: 30rem;
    background-image: url("../../assets/images/slp.jpg");
    background-size: contain;
    display: inline-block;
  }
}
