@import "../../styles/all";

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  background-color: #243b47;
  width: 100vw;
  // padding: 1rem 2rem;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    margin: 0 auto;
  }

  &__logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  &__logo {
    background-image: url("../../assets/images/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 4rem;
    height: 4rem;
  }

  &__text {
    padding-left: 1.5rem;
    color: white;
    font-size: 2rem;
  }

  &__nav-bar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 1rem;
  }

  &__nav-link {
    @include button;
    transition: color 0.3s ease-in;

    &--active {
      background-color: white;
      border-radius: 1.5rem;
      color: #243b47;
    }

    &:hover {
      border-bottom: white 2px solid;
    }
  }
}

@include tablet {
  .header {
    &__container {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 80rem;
      padding: 2rem 2rem 3rem 2rem;
    }

    &__logo-container {
      width: 30%;
      justify-content: flex-start;
      margin-bottom: 0;
    }

    &__nav-bar {
      width: 70%;
      justify-content: flex-end;
    }
  }
}
