@import "../../styles/all";

.signup {
  width: 100%;
  max-width: 20rem;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  color: rgb(133, 133, 133);
  background-color: white;
  height: 30rem;

  &__logo {
    width: 3rem;
    margin: 0 auto;
  }

  &__title {
    font-size: 2rem;
    margin: 0 auto;
  }

  &__text {
    font-size: 1.5rem;
    margin: 0 auto;
  }

  &__label {
    font-size: 1rem;
  }

  &__input {
    text-align: left;
    width: 100%;
    height: 2.5rem;
    padding: 0.25rem 1rem;
    border-radius: 0.5rem;
    outline: none;
    border: solid gray 2px;
    font-size: 1rem;

    &::placeholder {
      color: gray;
    }

    &:hover,
    &:focus {
      border-color: $bright_orange;
    }

    &--error {
      border-color: red;
      border-style: dashed;
    }
  }

  &__button {
    @include button;
    margin: 0 auto;
  }

  &__validation-message {
    font-size: 1rem;
    color: red;
    text-align: left;
    display: none;

    &--visible {
      display: block;
    }
  }
}
