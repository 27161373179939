@import "../../styles/all";

.signup-page {
  width: 100vw;
  height: 90vh;
  background-color: rgb(244, 244, 244);
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 9rem;
}
