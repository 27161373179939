@import "../../styles/all";

.home-signup {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 2rem 1.5rem;

  &__question {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  &__text {
    font-size: 1.4rem;
  }

  &__button {
    @include button;
    padding: 0.5rem 1rem 0.8rem 1rem;
    background-color: #243b47;
    border-radius: 1.5rem;
    font-size: 1.3rem;
  }
}

@include tablet {
  .home-signup {
    max-width: 80rem;
    padding: 2rem;

    &__text {
      font-size: 1.5rem;
    }

    &__button {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
}
