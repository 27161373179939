@import "../../styles/all";

.page-title {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  color: black;
  padding: 1rem;
  padding-top: 10rem;
  text-align: center;

  @include tablet {
    font-size: 1.8rem;
  }
}
